<template>
  <div class="row">
    <div class="col col-md-12">
      <card body-classes="standard-card-body" class="shadow">
        <div slot="header">
          <h4 class="card-title text-capitalize">{{ $t('callcenter.organizer') }}</h4>
        </div>

        <div class="d-flex justify-content-center">
          <div class="d-flex align-items-center m-2" v-for="(item, index) in statusData" v-bind:key="index">
            <div class="rounded p-2" :style="{backgroundColor:item.color}" v-if="item.show"></div>
            <div class="ml-2" v-if="item.show && item.id">{{$t('deal.status.' + item.id)}}</div>
          </div>
        </div>
        <DxScheduler
          :height="schedulerHeight"
          :time-zone="schedulerOptions.timeZone"
          :cell-duration="schedulerOptions.cellDuration"
          :start-day-hour="schedulerService.getStartDayHour()"
          :end-day-hour="schedulerService.getEndDayHour()"
          :date-serialization-format="schedulerOptions.dateSerializationFormat"
          :maxAppointmentsPerCell="schedulerOptions.maxAppointmentsPerCell"
          :groups="schedulerOptions.groups"
          :scrolling="schedulerOptions.scrolling"
          :show-current-time-indicator="schedulerOptions.showCurrentTimeIndicator"
          :first-day-of-week="0"
          :views="views"
          :current-view="schedulerOptions.currentView"
          :current-date="currentDate"
          :data-source="dataSource"
          :editing="editing"
          :on-appointment-updating="appointmentUpdating"
          :on-appointment-updated="appointmentUpdated"
          :on-appointment-click="appointmentClick"
          :on-appointment-dbl-click="(event) => event.cancel = true"
          :on-option-changed="optionChanged"
          :on-content-ready="onContentReady"
          appointment-template="AppointmentTemplateSlot"
          ref="dxScheduler"
        >

          <DxResource
            :allow-multiple="false"
            :data-source="statusData"
            :use-color-as-default="true"
            field-expr="status"
            label="Status"
          />

          <DxResource
            :allow-multiple="false"
            :data-source="resourcesData"
            field-expr="userId"
            label="Sale"
          />

          <template #AppointmentTemplateSlot="{ data }">
            <div class="position-relative">
              <div class="checked" v-if="data.appointmentData.checked">
                <octo-icon icon="check"/>
              </div>
              <div>{{data | optional('appointmentData.text')}}</div>
              <div class="small">
                {{data | optional('appointmentData.startDate') | time}} - {{data | optional('appointmentData.endDate') | time}}
              </div>
              <div class="small text-uppercase text-truncate" v-if="!isEventDisabled(data)">
                {{ $t('common.operator_prefix') }} : {{data | optional('appointmentData.operatorName')}}
              </div>
            </div>
          </template>

        </DxScheduler>
      </card>
    </div>

    <modal bodyClasses="p-1" modalClasses="modal-lg" centered :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ modalTitle }}
      </h5>
      <deal-modal-manage-appointment
        :lead="leadSelected"
        :deal="dealSelected"
        :deal-date="dealDateSelected"
        :key="`modal-${modalKey}`"
        ref="dealModalManageAppointment"
      />
      <template slot="footer">
        <base-button link class="text-uppercase" @click="updateAppointmentAndLeadNotes">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>

import Modal from "@/components/Modal";
import DealModalContent from "@/pages/Deals/components/DealModalContent";
import {mapGetters} from "vuex";
import {DxScheduler, DxResource} from 'devextreme-vue/scheduler';
import {locale, loadMessages} from "devextreme/localization";
import itMessages from "./resources/it.json";
import {endpoints} from "@/routes/endpoints";
import DealDate from "@/models/dealDate";
import Deal from "@/models/deal";
import Lead from "@/models/lead";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {permissionService} from "@/util/permission-service";
import DealModalManageAppointment from "./components/DealModalManageAppointment";
import {schedulerService} from "@/util/scheduler-service";

export default {
  name: "SalesDiaryPage",
  components: {
    DealModalManageAppointment,
    OctoIcon,
    DealModalContent,
    Modal,
    DxScheduler,
    DxResource
  },
  data() {
    return {
      schedulerService: schedulerService,
      permissionService: permissionService,
      dealDateSelected: this.$_.cloneDeep(DealDate),
      dealSelected: this.$_.cloneDeep(Deal),
      leadSelected: this.$_.cloneDeep(Lead),
      showModal: false,
      modalKey: 0,
      resourcesData: [],
      schedulerOptions: schedulerService.options,
      modalTitle: '',
      appointmentId: 0,
      dealId: 0,
      views: ['timelineDay'],
      currentTime: new Date(),
      currentDate: new Date(),
      dateFrom: this.$moment(),
      dateTo: this.$moment(),
      dataSource: [],
      statusData: schedulerService.statusData,
      scheduler: null,
      editing: {
          allowAdding: false,
          allowDeleting: false,
          allowUpdating: true,
          allowResizing: false,
          allowDragging: true
      },
    }
  },
  computed: {

    ...mapGetters({
      sales: 'common/sales',
      users: 'common/users',
      settings: 'common/settings',
      currentUser: 'auth/currentUser',
    }),

    schedulerHeight() {
      return this.sales?.length * 55;
    },

    userIsManager() {
      return permissionService.isAllowed([permissionService.CALL_CENTER_MANAGER_ROLE]);
    }
  },
  created() {
    loadMessages(itMessages);
    locale('it');
  },
  async beforeMount() {
    this.$fullLoading.show();
    this.resourcesData = schedulerService.resourcesData(this.sales);
    this.dataSource = await this.getData(this.dateFrom.format('YYYY-MM-DD'), this.dateTo.format('YYYY-MM-DD'));
    this.$fullLoading.hide();
    this.setEcho();
  },
  methods: {

    onContentReady(e) {
      this.scheduler = e.component;
      if (this.currentTime.getDate() === this.scheduler.getStartViewDate().getDate()) {
        this.scheduler.scrollTo(this.currentTime);
      }
    },

    async getData(dateFrom, dateTo) {
      try {
        const resp = await schedulerService.getData(dateFrom, dateTo, this.$api);
        return this.$_.map(resp, (item) => {
          return this.parseDealDate(item);
        });
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        return [];
      }
    },

    appointmentUpdating: function (event) {
      if(!this.userIsManager) {
        event.cancel = !(this.currentUser.id === event?.newData?.operatorId );
      }
    },
    appointmentUpdated: function (event) {

      schedulerService.updateDealDate(
        event?.appointmentData?.id,
        event?.appointmentData?.startDate,
        event?.appointmentData?.endDate,
        event?.appointmentData?.userId,
        this.$api
      ).then((resp) => {
        this.updateSingleDataSourceEvent(resp?.data?.data);
        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })

    },

    appointmentClick: function (event) {
      event.cancel = true;

      if (this.isEventDisabled(event)) {
        return;
      }

      if (this.userIsManager || this.currentUser.id === event?.appointmentData?.operatorId) {
        this.modalTitle = event.appointmentData.text;
        this.dealSelected = event.appointmentData.deal;
        this.leadSelected = event.appointmentData.lead;
        this.dealDateSelected = event.appointmentData.dealDate;
        this.modalKey++;
        this.showModal = true;
      }
    },

    updateAppointmentAndLeadNotes() {
      this.$fullLoading.show();
      this.$api.put(
        endpoints.DEAL_DATE_UPDATE_APPOINTMENT_AND_LEAD_NOTES.replace('{id}', this.dealDateSelected.id),
        this.$refs.dealModalManageAppointment.getModalData()
      )
        .then((resp) => {
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
          this.updateSingleDataSourceEvent(resp?.data?.data);
          this.showModal = false;
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },

    parseDealDate(item) {

      const itemParsed = schedulerService.parseDealDate(item);

      if(!(item?.dateable_id || null)) {
        itemParsed.disabled = true;
      } else {
        itemParsed.disabled = !(this.userIsManager || this.currentUser.id === item?.dateable?.lead?.user_id);
      }

      return itemParsed;
    },
    optionChanged: async function (data) {

      if(data.name === 'currentDate' || data.name === 'currentView') {
        try {
          await this.$nextTick()
          this.dateFrom = this.$moment(this.scheduler.getStartViewDate());
          this.dateTo = this.$moment(this.scheduler.getEndViewDate());
          this.dataSource = await this.getData(this.dateFrom.format('YYYY-MM-DD'), this.dateTo.format('YYYY-MM-DD'));
          this.$notify({type: 'info', message: this.$t('notifications.loading_success')});
        } catch (e) {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        }

      }

    },

    updateSingleDataSourceEvent(dealDate) {
      this.dataSource = this.$_.map(this.dataSource, (item) => {
        return item.id === dealDate.id
          ? this.parseDealDate(dealDate || this.$_.cloneDeep(DealDate))
          : item;
      });
    },

    isEventDisabled(event) {
      return !event?.appointmentData?.dealDate?.dateable_id
        || event?.appointmentData?.dealDate?.dateable_type === 'CallCenterLead';
    },

    setEcho() {
      try {

        this.$echo
          .channel(`sales-calendar-updated`)
          .listen('.sales-calendar-updated', (e) => {
            console.log('Join Channels sales-calendar-updated');
            this.handleCalendarUpdated(e);
          });

        this.$echo
          .channel(`sales-calendar-deleted`)
          .listen('.sales-calendar-deleted', (e) => {
            console.log('Join Channels sales-calendar-deleted');
            this.handleCalendarDeleted(e);
          })
      } catch (e) {
        console.log('Join Channels error', e);
      }

    },

    handleCalendarUpdated(e) {

      this.$emit('onHandleCalendarUpdated', e);
      if (e.owner_id === this.currentUser.id) {
        return;
      }
      const event = this.parseDealDate(e);
      const dataSource = this.$_.filter(this.$_.cloneDeep(this.dataSource), (item) => {
        return item.id !== event.id;
      });
      dataSource.push(event);
      this.dataSource = dataSource;
    },

    handleCalendarDeleted(e) {
      this.dataSource = this.$_.filter(this.dataSource, (item) => {
        return item.id !== e.id;
      });
    },
  }
}
</script>

<style scoped lang="scss">
.checked {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1.2em;
}
</style>
