<template>
  <div class="container-fluid px-0">
    <div class="row mb-2">
      <div class="col col-6">
        <ul class="list-group list-group-flush">
          <list-group-title-section-component :label="$t('common.registry')"/>
          <list-group-item-component :label="$t('fields.firstname')" :value="registry.name"/>
          <list-group-item-component :label="$t('fields.lastname')" :value="registry.surname"/>
        </ul>
      </div>
      <div class="col col-6">
        <ul class="list-group list-group-flush">
          <list-group-title-section-component :label="$t('common.contact_details')"/>
          <list-group-item-component v-for="(item, index) in emails" v-bind:key="`email-${index}`"
                                     :label="$t('common.email')"
                                     :value="item.email"
          />
          <list-group-item-component v-for="(item, index) in phones" v-bind:key="`tel-${index}`"
                                     :label="$t('fields.phone')"
                                     :value="item.phone"
          />
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ul class="list-group list-group-flush">
          <list-group-title-section-component :label="$t('common.courses')"/>
          <list-group-item-component
            v-bind:key="`course-${index}`"
            v-for="(course, index) in deal.lead.courses"
            :label="course.title"
            :value="course.code"
          />
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <ul class="list-group list-group-flush">
          <list-group-title-section-component :label="$t('callcenter.lead_data')"/>
          <list-group-item-component :label="$t('fields.date')">
            <template slot="value">
              <label-theme-component>
                {{ lead.date_in | date }} <span v-if="lead.date_out">- {{ lead.date_out | date }}</span>
              </label-theme-component>
            </template>
          </list-group-item-component>
          <list-group-item-component
            :label="$t('fields.operator')"
            :value="$options.filters.optional(users[lead.user_id], 'lastname') + ' ' +
                    $options.filters.optional(users[lead.user_id], 'firstname')"
          />
        </ul>
      </div>
      <div class="col-12 col-md-6">
        <ul class="list-group list-group-flush">
          <list-group-title-section-component :label="$t('callcenter.deal_data')"/>
          <list-group-item-component v-if="deal.status" :label="$t('fields.status')">
            <template slot="value">
              <badge :style="{backgroundColor: schedulerService.getDealStatusColor(deal.status)}"
                     class="text-uppercase"  v-if="deal.status">
                {{ $t('deal.status.' + deal.status) }}
              </badge>
            </template>
          </list-group-item-component>
          <list-group-item-component
            v-if="deal.sub_status"
            :label="$t('fields.substatus')"
            :value="$t('deal.status.' + deal.sub_status)"
          />
          <list-group-item-component
            :label="$t('fields.sale')"
            :value="$options.filters.optional(users[deal.user_id], 'lastname') + ' ' +
                    $options.filters.optional(users[deal.user_id], 'firstname')"
          />
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col col-6">
        <ul class="list-group list-group-flush">
          <list-group-title-section-component :label="`${$t('fields.notes')} ${$t('datatable.lead')}`"/>
          <li class="standard-li">
            <base-text-area v-model="leadNotes" class="flex-grow-1"/>
          </li>
        </ul>
      </div>
      <div class="col col-6 pl-0">
        <ul class="list-group list-group-flush">
          <list-group-title-section-component :label="`${$t('fields.notes')} ${$t('fields.deal')}`"/>
          <li class="standard-li">
            <base-text-area v-model="dealNotes" class="flex-grow-1"/>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ul class="list-group list-group-flush">
          <list-group-title-section-component :label="$t('callcenter.appointment_detail')"/>
        </ul>
      </div>
      <div class="col-6">
        <ul class="list-group list-group-flush">
          <list-group-item-component :label="$t('fields.sale')" :label-width="labelWidth">
            <template slot="value">
              <base-input class="mb-0 flex-grow-1">
                <el-select
                  v-model="dealDateLocal.user_id"
                  class="select-default text-uppercase"
                  :placeholder="$t('fields.choose')"
                >
                  <el-option
                    v-for="option in sales"
                    class="select-default text-uppercase"
                    :key="option.id"
                    :value="option.id"
                    :label="$options.filters.optional(users[option.id], 'lastname') + ' ' +
                            $options.filters.optional(users[option.id], 'firstname')"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </template>
          </list-group-item-component>
          <list-group-item-component :label="$t('fields.text')" :label-width="labelWidth">
            <template slot="value">
              <base-input v-model="dealDateLocal.text" type="text"/>
            </template>
          </list-group-item-component>
        </ul>
      </div>
      <div class="col-6">
        <ul class="list-group list-group-flush">
          <list-group-item-component :label="$t('fields.date')" :label-width="labelWidth">
            <template slot="value">
              <base-input class="mb-0 flex-grow-1">
                <el-date-picker
                  type="date"
                  v-model="appointmentDay"
                  value-format="yyyy-MM-dd"
                  format="dd/MM/yyyy"
                  :placeholder="$t('common.select_day')"
                  :picker-options="{firstDayOfWeek: 1}"
                />
              </base-input>
            </template>
          </list-group-item-component>
          <list-group-item-component :label="$t('callcenter.time')" :label-width="labelWidth">
            <template slot="value">
              <base-input class="mb-0 flex-grow-1">
                <el-time-select
                  v-model="appointmentTime"
                  format="HH:mm"
                  value-format="HH:mm:ss"
                  :placeholder="$t('callcenter.time')"
                  :picker-options="pickerOptions"
                />
              </base-input>
            </template>
          </list-group-item-component>
        </ul>
      </div>
    </div>
    <div class="row" v-if="userIsManager">
      <div class="col-12 d-flex justify-content-center align-items-center">
        <div class="mr-2">{{ $t('deal.appointment_confirmed') }}</div>
        <el-switch
          @change="(val) => dealDateLocal.status = val ? 'checked' : ''"
          :value="dealDateLocal.status === 'checked'"/>
      </div>
    </div>
  </div>
</template>

<script>
import Deal from "@/models/deal";
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import Lead from "@/models/lead";
import DealDate from "@/models/dealDate";
import {mapGetters} from "vuex";
import {TimeSelect, DatePicker, Select, Option, Switch} from "element-ui"
import {permissionService} from "@/util/permission-service";
import BaseTextArea from "@/components/Inputs/BaseTextArea";
import Registry from "@/models/registry";
import Badge from "@/components/Badge";
import {schedulerService} from "@/util/scheduler-service";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "DealModalManageAppointment",
  components: {
    LabelThemeComponent,
    Badge,
    BaseTextArea,
    ListGroupItemComponent,
    ListGroupTitleSectionComponent,
    [TimeSelect.name]: TimeSelect,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
  },
  props: {
    deal: {
      type: Object,
      default: _.cloneDeep(Deal)
    },
    lead: {
      type: Object,
      default: _.cloneDeep(Lead)
    },
    dealDate: {
      type: Object,
      default: _.cloneDeep(DealDate)
    }
  },
  data() {
    return {
      leadNotes: '',
      dealNotes: '',
      dealDateLocal: this.$_.cloneDeep(DealDate),
      dateObject: this.$moment(),
      appointmentTime: '12:00',
      appointmentDay: '',
      labelWidth: 140,
      schedulerService: schedulerService
    }
  },
  mounted() {
    this.leadNotes = this.$_.cloneDeep(this.lead?.notes);
    this.dealNotes = this.$_.cloneDeep(this.deal?.text);
    this.dealDateLocal = this.$_.cloneDeep(this.dealDate);

    this.dateObject = this.$moment(this.dealDateLocal.date_start, 'YYYY-MM-DD HH:mm');
    this.appointmentDay = this.dateObject.format('YYYY-MM-DD');
    this.appointmentTime = this.dateObject.format('HH:mm');
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
      sales: 'common/sales',
      settings: 'common/settings'
    }),

    registry() {
      return this.deal?.registry || this.$_.cloneDeep(Registry);
    },

    emails() {
      return this.deal?.registry?.emails || [];
    },

    phones() {
      return this.deal?.registry?.phones || [];
    },

    dateStart() {
      return this.appointmentDay + ' ' + this.appointmentTime + ':00';
    },

    dateEnd() {
      return this.appointmentDay + ' ' + this.$moment(this.appointmentTime, 'HH:mm')
        .add(this.settings?.callcenter_appointment_duration || 90, 'minutes')
        .format('HH:mm:ss')
    },

    pickerOptions() {
      return {
        start: this.$options.filters.padStart(this.settings?.callcenter_calendar_start, 2) + ':00',
        end: this.$options.filters.padStart(this.settings?.callcenter_calendar_end, 2) + ':00',
        step: '00:30',
      }
    },

    userIsManager() {
      return permissionService.isAllowed([permissionService.CALL_CENTER_MANAGER_ROLE]);
    }
  },
  methods: {
    getModalData() {
      return {
        start: this.dateStart,
        end: this.dateEnd,
        text: this.dealDateLocal.text,
        lead_notes: this.leadNotes,
        deal_notes: this.dealNotes,
        lead_id: this.lead.id,
        sale_id: this.dealDateLocal.user_id,
        appointment_status: this.dealDateLocal.status
      }
    }
  }
}
</script>

<style scoped>

</style>
